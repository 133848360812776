<template>
  <div class="news-block">
    <div class="container news-container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="main-title">Новини</h1>
        </div>
        <div class="col-md-6" v-for="(newsItem, newsIndex) in $news" :key="newsIndex">
          <NewsItem :newsItem="newsItem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItem from '@/components/NewsItem'

export default {
  components: {
    NewsItem
  }
}
</script>
